(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

require("./vendors/h5bp");

/* 
 * Mapa mediante la API de Google Maps
 */

function googleMaps() {

  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.763130, -60.521833);
  var centro = new google.maps.LatLng(-31.763130, -60.521833);

  // Estilos
  var estilos = [{ "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#E8E9EA" }] }, { "featureType": "landscape", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#b0b0b0" }] }, { "featureType": "water", "stylers": [{ "color": "#275781" }] }];

  // Opciones del mapa
  var mapOptions = {
    center: centro,
    zoom: 15,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "./assets/media/location-pin.png",
    map: map, //lo asocio al mapa
    optimized: false,
    title: 'Electro-Frio'
  });

  var infoWindow = new google.maps.InfoWindow({
    content: "<p style='text-align:center; color:#275781'>Electro-Frio <br>Barbagelata 37 | Paraná | Entre Ríos</p>"
  });

  google.maps.event.addListener(marker, 'click', function () {
    infoWindow.open(map, marker);
  });
}

/* 
 * Envío del formulario
 *
 * - Ejecucion en: $(document).ready()
 */

function formsGo() {

  $("#contact-submit").on('click', function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $('#contact input, #contact textarea').filter('[required]:visible').each(function (i, requiredField) {
      if ($(requiredField).val() == '') {
        if (okflag == true) $('#msg-all1').fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $('#msg1').addClass("backGload").fadeIn().load('contacto.php', {
      nombre: $('#contact-surname').val() + ", " + $('#contact-name').val(),
      email: $('#contact-email').val(),
      telefono: $('#contact-phone').val(),
      mensaje: $('#contact-message').val()
    }, function () {
      $('input, textarea').val("");
      $('#msg1').removeClass("backGload");
    });
  });
}

/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $('.home-slider').slider({
    height: 600,
    indicators: false,
    interval: 6000
  });
  $('.business-slider').slider({
    height: 540
  });
  $(".button-collapse").sideNav();
  $('.parallax').parallax();
  $('.goto').scrollSpy({
    scrollOffset: 0
  });
}

//$('.slider').slider('pause');

$('.next').click(function () {
  $('.slider').slider('next');
});
$('.prev').click(function () {
  $('.slider').slider('prev');
});

/* 
 * Camio algunos estilos al momento de scrollear
 *
 * - Ejecucion en: $(document).ready()
 */
function headerStuff() {
  //logo resize
  $(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 200) {
      $('header nav').addClass("escort");
    } else {
      $('header nav').removeClass("escort");
    }
  });
}

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function () {

  new WOW().init();
  headerStuff();
  googleMaps();
  formsGo();
  goMaterialize();
  // goSlick ();
  // goFancy();

  $(".video-info a").click(function (e) {
    e.preventDefault();
    $(".video-info a").removeClass("active");
    $(this).addClass("active");
    $(".franchise-videos iframe").attr("src", $(this).data("video"));
    $(".franchise-videos h2").html($(this).data("text"));
    // alert ($(this).data("video"));
  });
});

},{"./vendors/h5bp":2}],2:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
(function () {
  'use strict';

  var method;
  var noop = function noop() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

},{}]},{},[1])

